<template>
	<router-view/>
	<Footer></Footer>
</template>

<script>
import api from "@/services/api.js";
import Footer from '@/components/Footer.vue';
//make the call to getRootItem here and get the correct ID
export default {
	components: {
		Footer
	},
	methods: {
		async getData() {
        const { data } = await api.getRootItem();
				// console.log(data);
				this.$store.dispatch('updateRootItem',data)
		}
	},
	async created() {
    await this.getData();
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/base';
</style>