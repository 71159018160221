<template>
  <div class="theme-container">
    <PageContainer>
      <div class="flex-container">
        <div>
          <div class="heading">
            <p class="page-title">{{ data.homeTitle }}</p>
            <div class="page-subtitle" v-html="data.homeSubtitle"></div>
          </div>
          <div class="container-wrapper">
            <div class="flex-container-tiles">
              <template v-for="card in data.cards" :key="card.id">
                <div class="flex-4">
                  <div class="tile-wrapper">
                    <router-link
                      style="text-decoration: none"
                      :to="card.routeTo"
                    >
                      <div :class="`${card.class} tile`">
                        <div class="image-wrapper">
                          <img
                            :src="card.cardImage"
                            :alt="card.altImageDescription"
                          />
                        </div>
                        <p class="tile-main-title">{{ card.cardTitle }}</p>
                        <p v-if="card.id !== 5" class="tile-subtitle-title">
                          {{ card.cardDescription }}
                        </p>
                        <img
                          v-if="card.id === 5"
                          src="@/assets/images/slalom-white.svg"
                          alt="Slalom White Logo"
                          style="margin-top: 10px"
                        />
                      </div>
                    </router-link>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  </div>
</template>

<script>
import PageContainer from "@/components/PageContainer.vue";
import Loading from "@/components/Loading.vue";
// import api from '@/services/api.js';

export default {
  name: "HomeNavigation",
  props: {
    data: { options: Object },
    images: { options: Object },
  },

  components: {
    PageContainer,
    Loading,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.page-subtitle {
  color: $secondary-color-8;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 0;
  text-transform: uppercase;
}

.page-title {
  color: $secondary-color-7;
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 0;
}

.tile-main-title {
  color: $secondary-color-9;
  @include margin(0);
  @include margin-top(3);
  font-weight: 400;
  font-size: 18px;
  font-variant: small-caps;
  line-height: 1;
  text-transform: uppercase;
}

.tile-subtitle-title {
  font-size: large;
  font-weight: 600;
  color: $secondary-color-9;
  @include margin(0);
  font-size: 36px;
}

.tilesRow1 {
  margin-bottom: 35px;
}
.heading {
  padding: 0 1em;
  text-align: center;
}
.container-wrapper {
  @media only screen and (min-width: 992px) {
    max-width: 1038px;
  }
}
.tile-wrapper {
  padding: 1em;

  .tile {
    @include padding(4);
    @include animate;
    text-align: center;
    @include center;
    box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
    border-radius: 4px 4px 40px 4px;
    //min-width: 250px;
    min-height: 253px;
    &:hover {
      opacity: 0.7;
    }
    @include responsive(phone) {
      width: auto;
    }
    .image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
    }
  }
}

.logo {
  @include margin(3);
}

.summary {
  background-color: $primary-color-1;
}

.projectspotlight {
  background-color: $primary-color-2;
}

.special-events {
  background-color: $primary-color-3;
}

.timeline {
  background-color: $primary-color-4;
}

.survey-results {
  background-color: $primary-color-6
}

.looking-ahead {
  background-color: $primary-color-5
}

.flex-container {
  justify-content: space-around;

  @include responsive(tablet) {
    flex-wrap: wrap;
  }
  @include responsive(phone) {
    flex-wrap: wrap;
  }
}
.flex-container-tiles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include responsive(tablet) {
    flex-wrap: wrap;
  }
  @include responsive(phone) {
    flex-wrap: wrap;
  }
}
</style>
