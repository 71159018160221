import axios from 'axios';

const getQueryStringParameters = () =>{
	const params = new URLSearchParams(window.location.search)
	let entries = params.entries();
	return Object.fromEntries(entries);
}

export default {
	async authUser(email,password) {
		return await axios.post('/auth', {email: email, password: password, id: `${process.env.VUE_APP_YEAR_IN_REVIEW_SITE_ROOT_ITEM_ID}`});
	},
	async getRootItem() {
		const params = getQueryStringParameters();
		return await axios.get(`/rootitem/${process.env.VUE_APP_YEAR_IN_REVIEW_SITE_ROOT_ITEM_ID}`, {params});
	},

	async getSpecialEventsPage(guid) {
		const params = getQueryStringParameters();
		return await axios.get(`/specialeventspage/${guid}`, {params});
	},

	async getAllEvents(guid) {
		const params = getQueryStringParameters();
		return await axios.get(`/allevents/${guid}`, {params});
	},

	async getTimelinePage(guid) {
		const params = getQueryStringParameters();
		return await axios.get(`/timelinepage/${guid}`, {params});
	},

	async getSpotlightPage(guid) {
		const params = getQueryStringParameters();
		const spotlightUrl = `/spotlightpage/${guid}`
		return await axios.get(spotlightUrl, {params});
	},
	async getWhoIsSlalomPage(guid) {
		const params = getQueryStringParameters();
		return await axios.get(`/whoisslalompage/${guid}`, {params});
	},
	async getLookingAheadPage(guid) {
		const params = getQueryStringParameters();
		return await axios.get(`/lookingaheadpage/${guid}`, {params});
	},
	async getSummaryPage(guid) {
		const params = getQueryStringParameters();
		return await axios.get(`/summarypage/${guid}`, {params});
	},
	async getLoginPage(guid) {
		const params = getQueryStringParameters();
		return await axios.get(`/loginpage/${guid}`, {params});
	}
}
