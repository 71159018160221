import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';
import store from './store'

// axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.baseURL = 'http://localhost:3000';
if (process.env.VUE_APP_API_ENVIRONMENT == 'prod') {
	axios.defaults.baseURL = process.env.VUE_APP_API_URL
}
else {
	axios.defaults.baseURL = process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT
}

createApp(App).use(store)
	.use(router, axios, VueAxios)
	.mount('#app');
