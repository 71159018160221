<template>
<footer>
	<ul class="footer-container-list justify-content-md-evenly">
		<li class="col-12 col-md-auto"><img src="@/assets/images/slalom-white.svg" alt="Slalom White Logo" /></li>
		<template v-for="entry in footerContent.entries">
				<li v-html="entry.text" class="col-12 col-md-auto linkItem"></li>
		</template>
	</ul>
</footer>
</template>

<script>

export default {
	name: 'Footer',
	components: {
	},
	computed: {
		footerContent () {
		const defaultFooterContent = {
				entries:[]
		}
		const data = this.$store.state.rootItem;
		if(data && Object.entries(data).length>0){
        return data.footer;
      }
		return defaultFooterContent;
		},
	}
}
</script>
<style lang="scss">
	footer .linkItem a {
		color:#fff;
	}
	footer .linkItem p{
		margin-bottom:0px;
		display:inline;
	}
</style>
<style lang="scss" scoped>
@import '@/assets/scss/mixins';

footer {
	min-height: 1vh;
	@include bg-color($secondary-color-7);
	@include padding(2);
	@include margin-top(10);
	font-size: 10.75pt;
	line-height: 2;
	}
.footer-container-list {
	display: flex;
	align-items: center;
	margin-top:1rem;


	@include responsive(phone) {
		flex-wrap: wrap;
	}

	@include responsive(tablet) {
		flex-wrap: wrap;
	}

	li {
		text-align:center;
		@include responsive(phone) {
			margin-top:1rem;
			margin-bottom:1rem;
		}

		@include responsive(tablet) {
			margin-top:1rem;
			margin-bottom:1rem;
		}

		@include responsive(desktop) {
		}
		list-style-type: none;
		p{
			margin-bottom:0px;
		}
	}
}
</style>