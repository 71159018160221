<template>
  <div
      id="menu-container"
    class="bg-container"
    :class="{ active: isActive, hovered: hover || focus }"
  >
    <button
      @click="
        toggle();
        changeNavIcon();
      "
      @mouseover="hover = true"
      @focus="focus = true"
      @blur="focus = false"
      @mouseleave="
        hover = false;
        focus = false;
      "
      aria-label="Toggle Menu"
    >
      <img :src="navIconSrc" alt="Navigation Icon" />
    </button>
    <div
    id="menu-links"
      class="nav-links-container"
      :class="{ active: isActive, hovered: hover || focus }"
    >
      <ul v-if="isActive">
        <li v-for="link of links" :key="link.route"> 
          <router-link
            :to="link.route"
            v-on:click.passive="
              toggle();
              changeNavIcon();
            "
          >
          <div class="link-wrapper">
            <div class="link-image">
              <img :src="link.imgSrc" :alt="link.name" />
            </div>
            <div class="link-text">
              {{link.name}}
            </div>
          </div>
          </router-link>
        </li>
        <li>
            <img
              :src="homeContent.clientLogo"
              alt=""
              class="client-logo"
            />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as constants from "@/components/constants.js";
export default {
  name: "Navigation",
  data: () => ({
    isActive: false,
    hover: false,
    focus: false,
    navIconSrc: require("../assets/images/navigationmenu/nav-icon.svg"),
  }),
  computed: {
		links () {
		const defaultLinks = [constants.HomeNavItem]
		const data = this.$store.state.rootItem;
		if(data){
        return [...defaultLinks, ...data.tilePages.map((tilePage)=>({
          name: tilePage.pageTitle,
          route: tilePage.routeTo,
          imgSrc: tilePage.menuIcon,
        }))];
      }
		return defaultLinks;
		},
    homeContent () {
      const homeContent = {
        clientLogo: "",
      }
      const data = this.$store.state.rootItem;
      if(data){
        homeContent.clientLogo = data.metadata.client.clientLogoAlternative;
      }
      return homeContent;
    },    
	},
  methods: {
    toggle() {
      this.isActive = !this.isActive;
      var menuContainer = document.getElementById('menu-container');
      if(menuContainer){
        if(this.isActive){
          menuContainer.style.width = "300px";
          menuContainer.style.height = "500px" 
        }
        else{
          menuContainer.style.width = "";
          menuContainer.style.height = "";
        }
      }
    },
    changeNavIcon() {
      if (this.isActive) {
        this.navIconSrc = require("../assets/images/navigationmenu/nav-icon-close.svg");
      } else {
        this.navIconSrc = require("../assets/images/navigationmenu/nav-icon.svg");
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";


button {
  @include btn-reset;
  @include position(fixed, top 0, right 0);
  z-index: 5;
  padding: 1.2vmin;
  cursor: pointer;
  outline: none;

  @include responsive(tablet) {
    transform-origin: top right;
  }

  @include responsive(phone) {
    transform-origin: top right;
  }
}

.bg-container {
  @include position(fixed, top 0, right 0);
  @include dimensions(3em, 3em);
  @include animate;
  z-index: 5;
  background-color: $secondary-color-7;
  border-bottom-left-radius: .75em;
  transform-origin: top right;

  @include responsive(phone) {
    mix-blend-mode: normal;
  }

  &.hovered {
    @include dimensions(4vmax, 4vmax);
  }
  &.active{
    border-bottom-left-radius: 2em;
  }
}

.nav-links-container {
  @include margin-top(5);
  @include margin-left(1);
  @include animate;
  color: $secondary-color-11;
  opacity: 0;
  transition-property: opacity width;
  transition-duration: 0.5s;
  animation-timing-function: ease-in;
  width: 0px;
  @include animate-delay(0.2);
  &.active {
    z-index: 5;
    opacity: 1;
    width: auto;
  }

  a {
    color: $secondary-color-11;
    font-weight: 600;
    text-decoration: none;
  }

  a:hover {
    outline: none;
    @include animate;
    opacity: 0.5;
  }
}

ul {
  list-style-type: none;
  padding-left:2em;
}

li {
  font-weight: bold;
  text-align: left;
  padding: 0.5em;
}

.link-wrapper{
  display:flex;
  align-items: center;
  .link-image{
    width:32px;
    height:32px;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .link-text{ margin-left: 15px;}
}

.client-logo {
  @include dimensions(35%);
  margin-top: 15px;
}
</style>
