<template>
  <Alert :isActive="isError" v-if="isError">{{ errorMessage }}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
    <!-- Themes -->
    <Navigation></Navigation>
    <div class="top-logo-container">
      <img :src="homeImage" alt="Client-Slalom White Logo" />
    </div>
    <HomeNavigation :data="homeContent"></HomeNavigation>
  </Loading>
</template>

<script>
import api from "@/services/api.js";
import Loading from "@/components/Loading.vue";
import Navigation from "@/components/Navigation.vue";
import HomeNavigation from "@/components/HomeNavigation.vue";
import Alert from "@/components/Alert.vue";
function removeLastWord(str) {
  const lastIndexOfSpace = str.lastIndexOf(' ');

  if (lastIndexOfSpace === -1) {
    return str;
  }

  return str.substring(0, lastIndexOfSpace);
}
export default {
  data: () => ({
    content: {},
    homeContent: {
      homeTitle: "",
      homeSubtitle: "",
      cards: [],
    },
    homeImage: "",
    isLoading: false,
    isError: false,
    errorMessage: "",
  }),
  computed: {
    homeImage () {
      const data = this.$store.state.rootItem;
      if(data){
        return data.metadata.headerLogo;
      }
      return null;
    },
    homeContent () {
      const homeContent = {
        homeTitle: "",
        homeSubtitle: "",
        cards: [],
      }
      const data = this.$store.state.rootItem;
      if(data){
        homeContent.homeTitle = data.title;
        homeContent.homeSubtitle = data.subtitle.text;
        homeContent.cards = data.tilePages.map ((tilePage, index) => ({
          id:index,
          cardTitle: removeLastWord(tilePage.pageTitle),
          cardDescription: index != 5 ? tilePage.pageTitle.split(' ').pop(): '',
          cardImage: tilePage.pageIcon,
          routeTo: tilePage.routeTo,
          altImageDescription: `image${index}`,
          class: tilePage.class,
          guid: tilePage.id
        }));
      }
      return homeContent;
    }
  }, 

  async beforeRouteUpdate(to, from, next) {
    this.isError = false;
    next();
  },

  async created() {
    this.isError = false;
  },

  components: {
    Loading,
    Navigation,
    HomeNavigation,
    Alert,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";
.top-logo-container {
  background-color: $secondary-color-4;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
