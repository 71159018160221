import { createStore } from 'vuex'

export default createStore({
	state: {
		rootItem: null,
		pages: {}
	},
	mutations: {
		setRootItem(state, payload){
			state.rootItem = {...payload}
		},
		setPages(state, payload){
			state.pages = {...payload}
		},
		setLoginPage(state, payload){
			state.loginPage = {...payload}
		},
	},
	actions: {
		updateRootItem({commit}, newRootItem){
			commit('setRootItem', newRootItem)
			if(newRootItem.tilePages){
				const pageItems = {};
				newRootItem.tilePages.forEach(tilePage => {
					const stateName = tilePage.routeTo.replace(/[^a-zA-Z0-9]/g,'')
					if(stateName){
						pageItems[stateName] = tilePage
					}
					else{
						console.error('routeTo does not contain any valid characters: a-z,A-Z,0-9; from: ' + tilePage.routeTo);
					}
				});
				commit('setPages', pageItems) 
			}
			commit('setLoginPage', newRootItem.loginPage)
		}
	},
	modules: {
	}
})
