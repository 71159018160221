import { createRouter, createWebHistory } from 'vue-router';
import Home from '../pages/Home.vue';
import SESSION_AUTH_KEY from '@/config.js';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/summary',
		name: 'Summary',
		props: true,
		component: () =>
			import(/* webpackChunkName: "Summary" */ '../pages/Summary.vue'),
	},
	{
		path: '/project-spotlight',
		name: 'Project Spotlight',
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "Project Spotlight" */ '../pages/ProjectSpotlight.vue'
			),
	},
	{
		path: '/special-events',
		name: 'Special Events',
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "Project Spotlight" */ '../pages/SpecialEvents.vue'
			),
	},
	{
		path: '/timeline',
		name: 'Partnership Timeline',
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "PartnershipTimeline." */ '../pages/PartnershipTimeline.vue'
			),
	},
	{
		path: '/slalom',
		name: 'Who Is Slalom',
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "Who Is Slalom" */ '../pages/WhoIsSlalom.vue'
			),
	},
	{
		path: '/looking-ahead',
		name: 'Looking Ahead',
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "Looking Ahead" */ '../pages/LookingAhead.vue'
			),
	},
	{
		path: '/special-events/all-events/:clickedEventKey',
		name: 'All Events',
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "AllEvents" */ '../pages/AllEvents.vue'
			),
	},
	{
		path: '/login',
		name: 'Login',
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "Login" */ '../pages/Login.vue'
			),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return savedPosition || { top: 0 };
	},
});
if(window.ga){
	//analytics call
	window.ga('set', 'page', router.currentRoute.path);
	window.ga('send', 'pageview');
}

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next)=>{
	const isAuthenticated = sessionStorage.getItem(SESSION_AUTH_KEY)
	const fromQuery = Object.fromEntries(new URLSearchParams(window.location.search))
	if (to.name !== 'Login' && !isAuthenticated){
		next({
			name: 'Login',
			replace: true,
			query: fromQuery
		})
	} else if (to.name === 'Login' && isAuthenticated) {
		next({
			name:'Home',
			replace: true,
			query: fromQuery
		})
	} else {
		if(!hasQueryParams(to) && hasQueryParams(from)){
			next({name: to.name, query: fromQuery});
		} else {
			next()
		}
	}
})


router.afterEach(( to ) => {
	document.title = 'Client Year In Review - ' + to.name;
	if(window.ga){
		window.ga('set', 'page', to.path);
		window.ga('send', 'pageview');
	}
});

export default router;
